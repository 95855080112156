import { useContext } from 'react';

import TextField from '@guestyci/foundation/TextField';
import { Row } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import Icon from 'components/Icon';

import useLocale from 'hooks/useLocale';
import { DISCO_BE_PRICE_FORMAT } from 'constants/featureToggleNames';
import { getPriceStr } from 'utils';
import { CurrencyContext } from 'context/CurrencyContext';

export const PriceInfoV2 = ({ hostPayout, currency, roundPrice = 0 }) => {
  const { selectedCurrency } = useContext(CurrencyContext);
  const [, isPriceFormatEnabled] = useFeatureToggle(DISCO_BE_PRICE_FORMAT);
  const locale = useLocale();
  const price = roundPrice
    ? getPriceStr({
        price: hostPayout.toFixed(roundPrice),
        currency,
        locale,
        isPriceFormatEnabled,
      })
    : getPriceStr({
        price: hostPayout,
        currency,
        locale,
        isPriceFormatEnabled,
      });
  const label = t(
    "You'll pay <b>{price}</b>. The price displayed is an estimation based on the current exchange rate. The amount you'll pay may change based on the exchange rate at the moment of the final payment.",
    {
      price,
      b: (chunks) => <strong>{chunks}</strong>,
    }
  );

  return currency !== selectedCurrency ? (
    <Row className="pt-2">
      <Icon icon="info" size={63} />
      <TextField color="default" className="pl-2">
        {label}
      </TextField>
    </Row>
  ) : null;
};

const PriceInfo = ({ ratePlan, roundPrice = 0 }) => {
  const money = ratePlan?.money || null;
  const { selectedCurrency } = useContext(CurrencyContext);
  const locale = useLocale();
  const [, isPriceFormatEnabled] = useFeatureToggle(DISCO_BE_PRICE_FORMAT);
  const price = roundPrice
    ? getPriceStr({
        price: money?.hostPayout.toFixed(roundPrice),
        currency: money?.currency,
        locale,
        isPriceFormatEnabled,
      })
    : getPriceStr({
      price: money?.hostPayout, currency: money?.currency,
      locale,
      isPriceFormatEnabled,
    });
  const label = t(
    "You'll pay <b>{price}</b>. The price displayed is an estimation based on the current exchange rate. The amount you'll pay may change based on the exchange rate at the moment of the final payment.",
    {
      price,
      b: (chunks) => <strong>{chunks}</strong>,
    }
  );

  return money && money.currency !== selectedCurrency ? (
    <Row className="pt-2">
      <Icon icon="info" size={63} />
      <TextField color="default" className="pl-2">
        {label}
      </TextField>
    </Row>
  ) : null;
};

export default PriceInfo;
